<template>
  <div id="protraderPlatform">
    <div class="content_box">
      <div class="inner_max">
        <h3>{{ $t('menu.protraderPlatform') }}</h3>
        <div class="meta_box bg-mirage-gray p-3 p-md-4">
          <div class="box">
            <p v-html="$t('tradingView.subTitle02')"></p>
            <p v-html="$t('tradingView.intro02')"></p>
            <div class="list_wrapper">
              <div class="list_item_wrapper" v-for="item in descpList" :key="item.key">
                <img src="@/assets/images/yes_icon.png" alt />
                <div class="list_item_p">{{ item.descp }}</div>
              </div>
            </div>
            <div class="image_wrapper column_wrapper">
              <span>{{ $t('tradingView.intro03') }}</span>
              <img src="@/assets/images/tradingView.png" alt />
            </div>

            <div class="image_wrapper">
              <img src="@/assets/images/computer.png" alt />
            </div>

            <div class="button_wrapper text-center">
              <el-button class="bg-primary" data-testid="login" @click="goToTradingView">
                {{ $t('common.button.login') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import protraderPlatformMixins from '@/mixins/proTools/protraderPlatform';

export default {
  name: 'ProtraderPlatform',
  mixins: [protraderPlatformMixins],
  data() {
    return {
      descpList: [
        { key: 1, descp: this.$t('tradingView.descp1') },
        { key: 2, descp: this.$t('tradingView.descp6') },
        { key: 3, descp: this.$t('tradingView.descp7') },
        { key: 4, descp: this.$t('tradingView.descp8') },
        { key: 5, descp: this.$t('tradingView.descp9') },
        { key: 6, descp: this.$t('tradingView.descp10') }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/protraderPlatform.scss';
</style>
